<template>
  <div class="remotePop">
    <!-- map -->
    <div ref="map_div" class="map">
      <div ref="map_div2" class="con-map">
        <Map ref="map" style="width: 100%; height: 100%;" :loadMapName="loadMap" @item-click="onMapItemClick"
             @mapping-line-list="setMappingLineList"/>
        <MapButtonComponent ref="buttonMap" class="station-buttons"/>

      </div>
      <div class="remote-station-title">
        <h3>
          {{ stationInfo == null || stationInfo.managerData == null || stationInfo.managerData.tag == null ? '-' : stationInfo.managerData.tag + $t('station-number') + stationInfo.deviceName }}</h3>
      </div>
      <button class="remoteInBtn" @click="controlMode">{{ $t("btn-station-control") }}</button>
    </div>

    <!-- side info -->
    <div class="remote-side">
      <div class="rs-title">
        <h2>{{ $t("station-control-main-title") }}</h2>
        <button @click="openStationListModal"><img src="@/assets/images/icon_svg/ic_stationList.png" alt="icon">
        </button>
      </div>
      <div class="rs-info">
        <h2>{{ $t("station-control-sub-title-info") }}</h2>
        <ul>
          <li>
            <h4>{{ $t("monitoring-view-station") }}</h4>
            <h3>
              {{ stationInfo == null ? '-' : (stationInfo.managerData == null || stationInfo.managerData.tag == null ? '-' : stationInfo.managerData.tag) + $t('station-number') + stationInfo.deviceName }}</h3>
          </li>
          <li>
            <h4>{{ $t("event-data-site-manager") }}</h4>
            <h3>{{ stationInfo == null ? '-' : stationInfo.managerData.userName }}</h3>
          </li>
          <li>
            <h4>{{ $t("monitoring-view-department") }}</h4>
            <h3>{{ stationInfo == null ? '-' : stationInfo.managerData.userDepartmentName }}</h3>
          </li>
          <li>
            <h4>{{ $t("event-data-site-phone") }}</h4>
            <h3>{{ stationInfo == null ? '-' : getMask(stationInfo.managerData.userPhone) }}</h3>
          </li>
        </ul>
      </div>
      <div class="rs-info">
        <h2>{{ $t("station-control-sub-title-mission") }}</h2>
        <ul class="m-b-15">
          <li>
            <h4>{{ $t("mission-data-setting-speed") }}</h4>
            <h3>{{ missionInfo == null ? '-' : missionInfo.autoFlightSpeed }} m/s</h3>
          </li>
          <li>
            <h4>{{ $t("mission-data-setting-altitude") }}</h4>
            <h3>{{ missionInfo == null ? '-' : missionInfo.altitude }} m</h3>
          </li>
          <li>
            <h4>{{ $t("mission-data-setting-clear") }}</h4>
            <h3>{{ missionInfo == null ? '-' : missionInfo.finishedActionType }}</h3>
          </li>
          <li>
            <h4>{{ $t("top-select-site-view-list-sort-reg-date") }}</h4>
            <h3>{{ missionInfo == null ? '-' : moment(missionInfo.registerDate).format("YYYY.MM.DD HH:mm:ss") }}</h3>
          </li>
        </ul>
        <div class="ml-con">
          <h4>{{ $t("station-control-start-point") }}</h4>
          <ul>
            <li>
              <h4>{{ $t("monitoring-data-lat") }}:</h4>
              <h5>{{ missionLocationList.length > 0 ? missionLocationList[0].lng.toFixed(6) : '-' }} </h5>
            </li>
            <li class="m-b-0">
              <h4>{{ $t("monitoring-data-long") }}:</h4>
              <h5>{{ missionLocationList.length > 0 ? missionLocationList[0].lat.toFixed(6) : '-' }}</h5>
            </li>
          </ul>
        </div>
        <div class="ml-con m-b-0">
          <h4>{{ $t("station-control-end-point") }}</h4>
          <ul>
            <li>
              <h4>{{ $t("monitoring-data-lat") }}:</h4>
              <h5>{{
                  missionLocationList.length > 0 ? missionLocationList[missionLocationList.length - 1].lng.toFixed(6) : '-'
                }} </h5>
            </li>
            <li class="m-b-0">
              <h4>{{ $t("monitoring-data-long") }}:</h4>
              <h5>
                {{ missionLocationList.length > 0 ? missionLocationList[missionLocationList.length - 1].lat.toFixed(6) : '-' }}</h5>
            </li>
          </ul>
        </div>
      </div>
      <div class="rs-info">
        <h2>{{ $t("station-control-sub-title-schedule") }}</h2>
        <ul>
          <li>
            <h4>{{ $t("station-control-start-time") }}</h4>
            <h3>{{
                stationScheduleInfo == null || stationMissionJobLog == null ? '-' : moment(stationMissionJobLog.registerDate).format('YYYY.MM.DD') + " " + stationScheduleInfo.localTime
              }}</h3>
          </li>
          <li>
            <h4>{{ $t("station-control-total-fly-dist") }}</h4>
            <h3>{{ stationScheduleInfo == null ? '-' : totalDistance }} m</h3>
          </li>
          <li>
            <h4>{{ $t("station-control-total-fly-mission-dist") }}</h4>
            <h3>{{ stationScheduleInfo == null ? '-' : missionDistance }} m</h3>
          </li>
          <li>
            <h4>{{ $t("station-control-arrival-time") }}</h4>
            <h3>{{ addLocalTime }}</h3>
          </li>
          <li style="flex-direction: column; align-items: flex-start;">
            <div class="sd-progress">
              <div class="progress-bar"
                   :style=" missionDistance === '-'|| droneDistance === '-' ? 'width:0%': `width:${((droneDistance/missionDistance)*100).toFixed(2)}%` ">
                <div class="progress-info">
                  <img src="@/assets/images/icon_svg/drone_icon.png" alt="icon"/>
                  <span>{{ droneDistance }} m│{{
                      missionDistance === "-" || droneDistance === "-" ? "-" : ((droneDistance / missionDistance) * 100).toFixed(1)
                    }}%</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="rs-info border-0">
        <h2>{{ $t("station-control-sub-title-notification") }}</h2>
        <ul class="rs-notice">
          <!--            <li>-->
          <!--                <h4>미션 운행 종료</h4>-->
          <!--                <span>2024.08.12 11:23:45</span>-->
          <!--            </li>-->
        </ul>
      </div>
    </div>

    <!-- drone live -->
    <div class="live-box">
      <div class="vodview" ref="smView">
        <button class="switching-btn" @click="switchViews">
          <img src="@/assets/images/icon_svg/ic_switching.png">
        </button>
        <div class="video-box" ref="video"> <!--영상 송출 유무 표현-->
          <div class="play-on" v-if="droneChannelInfo != null">
            <WebRTC_WOWZA
                ref="dronePlayer"
                style="position: relative"
                class="play-on"
                v-if="droneChannelInfo !== null"
                v-show="droneChannelInfo.isLoad === true"
                :sdpUrl="droneChannelInfo.streamServer.properties.sdpUrl"
                :applicationName=" (droneChannelInfo.assignedDeviceType === 'Drone' || droneChannelInfo.assignedDeviceType === 'StationDrone') ? droneChannelInfo.streamServer.properties.droneApplication : droneChannelInfo.streamServer.properties.application"
                :streamName="droneChannelInfo.streamKey"
                :playerId="droneChannelInfo.channelId"
                :deviceType="droneChannelInfo.assignedDeviceType"
                :isMutedWebRtc="true"
                :isShowLiveText="false"
                :isSpeakerButtonShow="false"
                :isModelButtonShow="false"
                @isLoadedmetadata="playerLoadData"
                @playerRemoveId="playerRemoveId"
            />
            <!--                <videoPlayer :src="droneChannelManagerInfo.streamUrl" :isVod="false" :playerId="droneChannelManagerInfo.channelId" :isAuto ="true" :isDeleteButtonShow="false" :contentsId="null" :isControls="false" />-->
          </div>
          <div class="play-off" v-if="droneChannelInfo == null">
            <img src="@/assets/images/play-off-icon.png" alt="icon"/>
            <h2>{{ $t("monitoring-view-no-signal") }}</h2>
          </div>
        </div>
        <div class="video-info">
          <div class="video-top">
            <!--종류에 따른 이미지 제어-->
            <div class="emphysema-img">
              <div class="drone-image" @click="moveMapCenter('Drone')">
                <img v-if="stationInfo != null && stationInfo.modelName === 'DJI Dock'"
                     src="@/assets/images/drone_img/dji_matrice_300_RTK.png" alt="drone"/>
                <img v-if="stationInfo != null && stationInfo.modelName === 'DJI Dock2'"
                     src="@/assets/images/drone_img/dji_m3d.png" alt="drone"/>
              </div>
            </div>
            <div class="battery-info">
              <h4>
                <span>{{
                    storeDroneInfo == null || stationInfo == null || stationInfo.childStationDevice == null ? "0" : storeDroneInfo.battery.capacityPercent
                  }}</span>
                <span>%</span>
              </h4>
              <ul>
                <li v-for="(b_index) in 5" :key="'battery' + b_index"
                    :class=" storeDroneInfo == null || (stationInfo != null && stationInfo.childStationDevice == null) ? 'off' : (5-b_index)*20 <=  storeDroneInfo.battery.capacityPercent ?  'on' : 'off'  "/>
              </ul>
            </div>
          </div>
          <div class="drone-video-info">
            <div class="video-title" style="position: relative;">
              <button
                  @click="playerReload(storeDroneInfo === null || stationInfo == null || stationInfo.childStationDevice == null ? null : stationInfo.childStationDevice.stationDeviceId, 'drone')"
                  style="height: 28px;width: 40px;position: absolute;right: 0;border: 0;border-left: 1px solid #808080;">
                <img style="width: 13px;padding: 0 " src="@/assets/images/icon_svg/ic_refresh.png"></button>
              <h2>
                <span>{{ $t("monitoring-view-device") }}</span>
                <span>{{
                    storeDroneInfo == null || stationInfo == null || stationInfo.childStationDevice == null ? "-" : stationInfo.childStationDevice.deviceName
                  }}</span>
              </h2>
              <h4>
                <span>{{ $t("monitoring-view-user") }} </span>
                <span>{{
                    storeDroneInfo == null || stationInfo == null || stationInfo.childStationDevice == null ? "-" : stationInfo.childStationDevice.managerData.userName
                  }}</span>
              </h4>
              <h4>
                <span>{{ $t("device-data-manager-dept-name") }} </span>
                <span style=" width: 50px;overflow: hidden;text-overflow: ellipsis; white-space: nowrap;">{{
                    storeDroneInfo == null || stationInfo == null || stationInfo.childStationDevice == null ? "-" : stationInfo.childStationDevice.managerData.userDepartmentName
                  }}</span>
              </h4>
            </div>
            <div class="video-value" style="font-size: 1.0rem;">
              <div class="value-box">
                <h4>{{ $t("monitoring-data-alt") }}</h4>
                <h2><span class="dataValue">{{
                    storeDroneInfo == null ? "-" : storeDroneInfo.elevation.toFixed(1)
                  }}</span> m</h2>
              </div>
              <div class="value-box">
                <h4>{{ $t("monitoring-data-dist") }}</h4>
                <h2><span class="dataValue">{{
                    storeDroneInfo == null ? "-" : storeDroneInfo.homeDistance.toFixed(1)
                  }}</span> m</h2>
              </div>
              <div class="value-box">
                <h4>{{ $t("monitoring-data-hs") }}</h4>
                <h2><span class="dataValue">{{
                    storeDroneInfo == null ? "-" : storeDroneInfo.horizontalSpeed.toFixed(1)
                  }}</span> m/s</h2>
              </div>
              <div class="value-box">
                <h4>{{ $t("monitoring-data-vs") }}</h4>
                <h2><span class="dataValue">{{
                    storeDroneInfo == null ? "-" : storeDroneInfo.verticalSpeed.toFixed(1)
                  }}</span> m/s</h2>
              </div>
            </div>
            <div class="video-coordinate" style="font-size: 1.0rem;">
              <div class="coordinate-row">
                <h4>{{ $t("monitoring-data-lat") }}</h4>
                <h2><span class="dataValue">{{
                    storeDroneInfo == null || storeDroneInfo.latitude == 0.0 ? "-" : storeDroneInfo.latitude.toFixed(6)
                  }}</span></h2>
              </div>
              <div class="coordinate-row">
                <h4>{{ $t("monitoring-data-long") }}</h4>
                <h2><span class="dataValue">{{
                    storeDroneInfo == null || storeDroneInfo.longitude == 0.0 ? "-" : storeDroneInfo.longitude.toFixed(6)
                  }}</span></h2>
              </div>
              <div class="coordinate-row" style="width: 30%;">
                <h4>{{ $t("drone-data-wind-speed") }}</h4>
                <h2>
                  <span>{{ storeDroneInfo == null || storeDroneInfo == undefined ? '-' : (storeDroneInfo.windSpeed / 10).toFixed(1) }} </span>
                  &nbsp; m/s<!-- 진행없으면 "-" --></h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 미션 포인트 정보 -->
    <div class="rm-point-list">
      <h2>{{ $t("station-control-way-set-list") }}</h2>
      <ul>
        <li v-for="location in missionLocationList" :key="'location_'+location.index"
            @click="setMapCenterForMissionLocation(location)">
          <div class="rm-list-top">
            <h4>{{ location.index + 1 }} {{ $t("station-control-way-number") }}</h4>
            <div class="loc-info">
              <h5>{{ $t("monitoring-data-lat") }}<span>{{ location.lng.toFixed(6) }}</span></h5>
              <h5 class="m-b-0">{{ $t("monitoring-data-long") }}<span>{{ location.lat.toFixed(6) }}</span></h5>
            </div>
          </div>
          <div class="rm-list-bottom">
            <div class="rm-value">
              <h5>{{ $t("way-data-speed") }}</h5>
              <h4><span> {{ missionInfo == null ? '-' : missionInfo.autoFlightSpeed }}</span>m/s</h4>
            </div>
            <div class="rm-value">
              <h5>{{ $t("monitoring-data-alt") }}</h5>
              <h4><span> {{ location.altitude }}</span>m</h4>
            </div>
            <div class="rm-value m-0">
              <h5>{{ $t("way-data-camera") }}</h5>
              <h4><span>{{
                  location == null || location.gimbalType == 'None' ? 'None' : location.gimbalType.replace("Gimbal", "")
                }}</span>&#730;</h4>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- 스테이션 영상 -->
    <div class="station-view">
      <div class="station-live">
        <div class="video-box">
          <div class="play-on" v-if="dockChannelInfo != null">
            <WebRTC_WOWZA style="position: relative"
                          class="play-on"
                          v-if="dockChannelInfo != null"
                          v-show="dockChannelInfo.isLoad == true"
                          :sdpUrl="dockChannelInfo.streamServer.properties.sdpUrl"
                          :applicationName=" (dockChannelInfo.assignedDeviceType === 'Drone' || dockChannelInfo.assignedDeviceType === 'StationDrone')? dockChannelInfo.streamServer.properties.droneApplication : dockChannelInfo.streamServer.properties.application"
                          :streamName="dockChannelInfo.streamKey"
                          :playerId="dockChannelInfo.channelId"
                          :deviceType="dockChannelInfo.assignedDeviceType"
                          :isShowLiveText="false"
                          :isSpeakerButtonShow="false"
                          :isModelButtonShow="false"
                          @isLoadedmetadata="playerLoadData"
                          @playerRemoveId="playerRemoveId"
            />

            <!--                <videoPlayer :src="dockChannelManagerInfo.streamUrl" :isVod="false" :playerId="dockChannelManagerInfo.channelId" :isAuto ="true" :isDeleteButtonShow="false" :contentsId="null" :isControls="false"  />-->
          </div>
          <div class="play-off" v-if="dockChannelManagerInfo == null || dockChannelManagerInfo.streamUrl == null">
            <img src="@/assets/images/play-off-icon.png" alt="icon"/>
            <h2>{{ $t("monitoring-view-no-signal") }}</h2>
          </div>
        </div>
      </div>
      <div class="sv-con">
        <div class="sv-img" @click="moveMapCenter('Dock')">
          <img src="@/assets/images/drone_img/dock2.png" alt="station"/>
        </div>
        <div class="sv-info">
          <h4>{{ stationName }}</h4>
          <div class="sv-value">
            <div class="sv-situation">
              <div class="sv-situation-row">
                <h4>{{ $t("event-data-site-status") }}</h4>
                <h5>
                  <span class="sv-mark"
                        :class=" stationInfo == null || stationInfo.modeCode == null || stationInfo.modeCode == EDockModeCode.Disconnected ? 'sv-bad' : 'sv-good' "></span>
                  <span> {{
                      stationInfo == null || stationInfo.modeCode == null ? EDockModeCode.getData(EDockModeCode.Disconnected).showName : EDockModeCode.getData(stationInfo.modeCode).showName
                    }}</span> <!-- 이상있으면 "이상감지" -->
                </h5>
              </div>
              <div class="sv-situation-row">
                <h4>{{ $t("station-data-communication") }}</h4>
                <h5>
                  <span> {{ storeDockInfo == null || storeDockInfo.base == undefined ? '-' : (storeDockInfo.base.networkState.rate / 1024).toFixed(2) }} </span>
                  &nbsp; Mb/s <!-- 진행없으면 "-" -->
                </h5>
              </div>
            </div>
            <div class="sv-situation" style="font-size: 1.0rem;padding-top:5px;">
              <div class="sv-situation-row m-b-0">
                <h4>{{ $t("drone-data-wind-speed") }}</h4>
                <h5>
                  <span> {{ storeDockInfo == null || storeDockInfo.base == undefined ? '-' : storeDockInfo.base.windSpeed.toFixed(1) }} </span>
                  &nbsp; m/s <!-- 진행없으면 "-" -->
                </h5>
              </div>
              <div class="sv-situation-row m-b-0">
                <h4>{{ $t("way-data-precipitation") }}</h4>
                <h5>
                  <span> {{
                      storeDockInfo == null || storeDockInfo.base == undefined ? '-' : RainfallEnum.getData(storeDockInfo.base.rainfall).showName
                    }}</span> <!-- 진행없으면 "-" -->
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- list modal -->
    <div v-if="showStationListModal" class="sv-modal-mask">
      <div class="sv-modal-wrapper">
        <div class="sv-modal-container">
          <div class="modal-title">
            <h3 style="margin-bottom: 0;">{{ $t("station-control-modal-title") }}</h3>
            <button @click="closeStationListModal"><img src="@/assets/images/icon_svg/reset.png" alt="icon"
                                                        style="width: 30px;"/></button>
          </div>
          <div class="sv-modal-content">
            <div class="sv-list-info">
              <div class="info-count">
                <h4>
                  <span>{{ $t("user-home-site-on-progress") }}</span>
                  <span>{{ stationInfo == null ? '-' : (stationInfo.managerData == null || stationInfo.managerData.tag == null ? '-' : stationInfo.managerData.tag) + $t('station-number') + stationInfo.deviceName }}</span>
                </h4>
                <h4>
                  <span>{{ $t("station-control-modal-select") }}</span>
                  <span> {{ selectedCheckbox == null ? '-' : (stationDeviceListMap[selectedCheckbox] == undefined || stationDeviceListMap[selectedCheckbox].device.managerData == null || stationDeviceListMap[selectedCheckbox].device.managerData.tag == null ? '-' : stationDeviceListMap[selectedCheckbox].device.managerData.tag) + $t('station-number') + stationDeviceListMap[selectedCheckbox].device.deviceName }}</span>
                </h4>
              </div>
              <button @click="stationListModalRefresh"><img src="@/assets/images/icon_svg/ic_refresh.png"></button>
            </div>
            <ul class="sv-list">
              <li v-for="(n, index) in 8" :key="index">
                <div class="st-view" style="height:155px;">
                  <div v-if="stationDeviceListMap[index] !== undefined && stationDeviceListMap[index].device.modeCode !== null && stationDeviceListMap[index].modeCode !== EDockModeCode.Disconnected.code ">
                    <template v-if="stationDeviceListMap[index] !== undefined && stationDeviceListMap[index].device.modeCode !== null && stationDeviceListMap[index].modeCode !== EDockModeCode.Disconnected.code ">
                      <Video
                          :ref="`video_${channelListMap[stationDeviceListMap[index].device.managerData.referenceDeviceId].channelId}`"
                          v-if="channelListMap[stationDeviceListMap[index].device.managerData.referenceDeviceId] != undefined"
                          v-show="channelListMap[stationDeviceListMap[index].device.managerData.referenceDeviceId].isLoad == true"
                          :src="getHlsUrl(channelListMap[stationDeviceListMap[index].device.managerData.referenceDeviceId])"
                          :isAuto="true"
                          :isVod="false"
                          :isControls="false"
                          :playerId="channelListMap[stationDeviceListMap[index].device.managerData.referenceDeviceId].channelId"
                          :isShowLiveText = "false"
                          :channelIndex="index"
                          :isShowVolum="false"
                          :isShowFullScreen="false"
                          :deviceType="channelListMap[stationDeviceListMap[index].device.managerData.referenceDeviceId].assignedDeviceType"
                          @playerRemoveId="playerRemoveList"
                          @isLoadedmetadata="playerLoadDataList"/>
                    </template>
                  </div>
                  <input type="checkbox" :checked="selectedCheckbox === index" @change="onCheckboxChange(index)" :disabled="stationDeviceListMap[index] === null || stationDeviceListMap[index] === undefined || stationInfo.stationDeviceId == stationDeviceListMap[index].device.stationDeviceId">
                </div>
                <div class="st-info">
                  <div class="st-title">
                    <h2 :style="stationDeviceListMap[index] !== undefined && stationDeviceListMap[index] !== null && stationInfo.stationDeviceId == stationDeviceListMap[index].device.stationDeviceId ? 'color: #d69236' : 'color : #fff'">
                      {{
                        stationDeviceListMap[index] === null || stationDeviceListMap[index] === undefined ? '-' : (stationDeviceListMap[index].device.managerData == null || stationDeviceListMap[index].device.managerData.tag == null ? '-' : stationDeviceListMap[index].device.managerData.tag) + ' '+ $t('station-number') +
                            stationDeviceListMap[index].device.deviceName
                      }}</h2>
                    <h4>{{
                        stationDeviceListMap[index] === null || stationDeviceListMap[index] === undefined || stationDeviceListMap[index].children === null ? '-' : stationDeviceListMap[index].children.deviceName
                      }}</h4>
                  </div>
                  <div class="st-situation">
                    <h4>
                      <span class="st-mark" :class="stationDeviceListMap[index] == null || stationDeviceListMap[index].device.modeCode == null || stationDeviceListMap[index].device.modeCode == EDockModeCode.Disconnected.code ? 'st-bad'  : 'st-good'  ">
                      </span>
                      <span>{{
                          stationDeviceListMap[index] === undefined || stationDeviceListMap[index] === null || stationDeviceListMap[index].device.modeCode == null ? EDockModeCode.getData(EDockModeCode.Disconnected).showName : EDockModeCode.getData(stationDeviceListMap[index].device.modeCode).showName
                        }}</span>
                    </h4>
                    <h4>
                      <span class="st-mark" :class="stationDeviceListMap[index] == null || stationDeviceListMap[index].children == null ||  stationDeviceListMap[index].children.modeCode == null || stationDeviceListMap[index].children.modeCode == EModeCode.Disconnected.code ? 'st-bad' : stationDeviceListMap[index].children.modeCode == EModeCode.Return_To_Home.code || stationDeviceListMap[index].children.modeCode == EModeCode.Landing.code ? 'st-rtl' : 'st-good' "></span>
                      <span>{{
                          stationDeviceListMap[index] === undefined ||stationDeviceListMap[index] === null || stationDeviceListMap[index].children.modeCode == null ? EModeCode.getData(EModeCode.Disconnected).showName : EModeCode.getData(stationDeviceListMap[index].children.modeCode).showName
                        }}</span>
                    </h4>
                  </div>
                </div>
                <div class="station-battery">
                  <div class="station-battery-content">
                    <div class="station-battery-box">
                      <div class="battery-volume" :style="[batteryStyle[index]]"></div>
                    </div>
                    <h4>{{ stationDeviceListMap[index] === undefined || stationDeviceListMap[index].children.batteryPercent === null || stationDeviceListMap[index].children.batteryPercent === undefined ? '0' : stationDeviceListMap[index].children.batteryPercent }} <span>%</span></h4>
                  </div>

                  <h4>
                    <img src="@/assets/images/ic_battery_count.png" alt="icon" />
                    <span>{{ stationDeviceListMap[index] === undefined || stationDeviceListMap[index].children.batteryLifeCycle === null || stationDeviceListMap[index].children.batteryLifeCycle === undefined ? '-' :stationDeviceListMap[index].children.batteryLifeCycle }} </span>
                  </h4>
                </div>
              </li>
            </ul>
          </div>
          <div class="sv-modal-btn">
            <button class="submit-btn" @click="selectStation">{{ $t("btn-confirm") }}</button>
            <button class="close-btn" @click="closeStationListModal">{{ $t("btn-cancel") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
/* new css */
.m-0 {
  margin: 0px !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.border-0 {
  border: 0 !important;
}

.remotePop {
  position: relative;
  height: 100vh;
}

.con-map {
  width: 100%;
  height: 100%;
}

.map {
  width: 100%;
  height: 100%;
  position: relative;
}

.flight-view {
  width: 100%;
  height: 100vh;
}

.remote-station-title {
  background: #101010;
  padding: 5px 40px;
  border-radius: 5px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 100;
}

.remote-station-title > h3 {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
}

.remoteInBtn {
  position: absolute;
  z-index: 100;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 400px;
  background: #0080ff;
  border: 0;
  border-radius: 5px;
  height: 40px;
  color: #fff;
  font-size: 1.4rem;
  margin-bottom: 15px;
}

/* side css */
.remote-side {
  position: absolute;
  left: 0;
  top: 0;
  width: 280px;
  height: 100%;
  background: #232323;
}

.rs-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #707070;
}

.rs-title > h2 {
  margin: 0;
  font-size: 1.6rem;
  color: #fff;
  font-weight: bold;
}

.rs-title > button {
  border: 0;
}

.rs-title > button > img {
  width: 20px;
}

.rs-info {
  padding: 15px;
  border-bottom: 1px solid #707070;
}

.rs-info > h2 {
  font-size: 1.4rem;
  color: #fff;
  margin-bottom: 15px;
  font-weight: bold;
}

.rs-info > ul > li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.rs-info > ul > li:last-child {
  margin-bottom: 0px;
}

.rs-info > ul > li > h4 {
  color: #c9c9c9;
  font-size: 1.2rem;
  font-weight: 300;
}

.rs-info > ul > li > h3 {
  font-size: 1.4rem;
  color: #fff;
  font-weight: 300;
}

.rs-info > .ml-con {
  padding: 5px 10px;
  background: #3c3c3c;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5px;
}

.rs-info > .ml-con > h4 {
  color: #c9c9c9;
  font-size: 1.2rem;
  font-weight: 400;
}

.rs-info > .ml-con > ul > li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.rs-info > .ml-con > ul > li > h4 {
  font-size: 1.2rem;
  color: #c9c9c9;
  font-weight: 300;
  margin-right: 5px;
}

.rs-info > .ml-con > ul > li > h5 {
  font-size: 1.2rem;
  color: #fff;
  font-weight: 300;
}

.sd-progress {
  width: 100%;
  margin-top: 45px;
  height: 15px;
  position: relative;
  display: flex;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  align-items: center;
}

.sd-progress > .progress-bar {
  position: relative;
  width: 30%;
  height: 8px;
  background: #54FFF9;
}

.sd-progress > .progress-bar::before {
  content: '';
  position: absolute;
  width: 248px;
  height: 1px;
  top: 4px;
  background: #ddd;
}

.sd-progress > .progress-bar::after {
  content: '';
  position: absolute;
  height: 25px;
  border: 1px dashed #54FFAA;
  right: 0;
  top: -20px;
}

.sd-progress > .progress-bar > .progress-info {
  position: absolute;
  top: -40px;
  width: 150px;
  right: -114px;
}

.sd-progress > .progress-bar > .progress-info > span {
  color: #fff;
  font-size: 1.2rem;
  margin-left: 5px;
  background: #0080ff;
  padding: 0 5px;
}

.rs-info > .rs-notice {
  max-height: 210px;
  height: 210px;
  overflow-y: auto;
  background: #3c3c3c;
}

.rs-info > .rs-notice > li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  border-bottom: 1px solid #707070;
}

.rs-info > .rs-notice > li > h4 {
  font-size: 1.4rem;
  color: #fff;
  margin-bottom: 5px;
}

.rs-info > .rs-notice > li > span {
  color: #c9c9c9;
  font-weight: 300;
  font-size: 1.2rem;
}

/* vod css */
.live-box {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 375px;
  height: 298px;
  background: #232323;
}

.switching-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  background: #101010cc;
}

.vodview {
  position: relative;
  display: flex;
  height: 100%;
  background: #232323;
  margin-bottom: 15px;
  flex-direction: column;
}

.video-box {
  width: 100%;
  height: 100%;
  background: #000000;
  position: relative;
}

.video-box > .play-on {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-box > .play-off {
  width: 100%;
  text-align: center;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.video-box > .play-off > h2 {
  font-size: 1.2rem;
  color: #fff;
  margin-top: 10px;
}

.video-box > .play-off > img {
  width: 25px;
}

.video-box > .play-off-4 {
  width: 100%;
  text-align: center;
  padding-top: 56.25%;
}

.video-box > .play-off-4 > h2 {
  font-size: 1.2rem;
  color: #fff;
  margin-top: 10px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 52%;
  left: 50%;
}

.video-box > .play-off-4 > img {
  width: 25px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 48%;
  left: 50%;
}

.video-box > .play-off-9 {
  width: 100%;
  text-align: center;
  padding-top: 56.25%;
}

.video-box > .play-off-9 > h2 {
  font-size: 1.2rem;
  color: #fff;
  margin-top: 10px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 52%;
  left: 50%;
}

.video-box > .play-off-9 > img {
  width: 25px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 48%;
  left: 50%;
}

.video-info {
  width: 100%;
  color: #fff;
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #4f4f4f;
}

.video-info > .video-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  background: #232323;
  flex-direction: column;
  width: 80px;
  border-right: 1px solid #4f4f4f;
}

.video-info > .video-top > .emphysema-img {
  width: 70px;
  background: #232323;
  border-radius: 5px;
}

.video-info > .video-top > .emphysema-img > .drone-image {
  width: 100%;
  height: 50px;
  background: #232323;
  border-radius: 5px;
  padding: 0px;
  text-align: center;
  display: flex;
  align-items: center;
}

.video-info > .video-top > .emphysema-img > .phone-image {
  width: 100%;
  height: 50px;
  background: #232323;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
}

.video-info > .video-top > .emphysema-img > .drone-image > img {
  width: 100%;
}

.video-info > .video-top > .emphysema-img > .phone-image > img {
  height: 100%;
}

.video-info > .video-top > .battery-info {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.video-info > .video-top > .battery-info > ul {
  display: flex;
  margin-bottom: 0px;
}

.video-info > .video-top > .battery-info > ul > li {
  height: 18px;
  width: 5px;
  background: #878787;
  margin-right: 2px;
  border-radius: 2px;
}

.video-info > .video-top > .battery-info > ul > li.on {
  background: #1cdfa0;
}

.video-info > .video-top > .battery-info > ul > li.off {
  background: #878787
}

.video-info > .video-top > .battery-info > h4 {
  text-align: right;
  margin-right: 5px;
}

.video-info > .video-top > .battery-info > h4 > span {
  font-size: 1.1rem;
  font-weight: 400;
}

.video-info > .drone-video-info {
  width: calc(100% - 80px);
}

.video-info > .drone-video-info > .video-title {
  border-bottom: 1px solid #4f4f4f;
  padding: 7px 0px 7px 5px;
  display: flex;
  align-items: center;
}

.video-info > .drone-video-info > .video-title > h2 {
  font-size: 0.9rem;
  margin-bottom: 0px;
  letter-spacing: -1px;
  margin-right: 10px;
}

.video-info > .drone-video-info > .video-title > h2 > span {
  font-size: 1rem;
  margin-bottom: 0px;
  letter-spacing: -1px;
}

.video-info > .drone-video-info > .video-title > h2 > span:nth-child(1) {
  color: #c9c9c9;
  margin-right: 5px;
  font-size: 0.9rem;
}

.video-info > .drone-video-info > .video-title > h4 {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0px;
  display: flex;
  margin-right: 10px;
}

.video-info > .drone-video-info > .video-title > h4:nth-child(3) {
  margin-bottom: 0;
}

.video-info > .drone-video-info > .video-title > h4 > span {
  font-size: 1rem;
}

.video-info > .drone-video-info > .video-title > h4 > span:nth-child(1) {
  color: #c9c9c9;
  margin-right: 5px;
  font-size: 0.9rem;
}

.video-info > .drone-video-info > .video-value {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 7px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 3px;
}

.video-info > .drone-video-info > .video-value > .value-box {
  width: 25%;
  display: flex;
  align-items: center;
}

.video-info > .drone-video-info > .video-value > .value-box > h4 {
  font-size: 0.9rem;
  color: #c9c9c9;
  margin-bottom: 0px;
  margin-right: 5px;
  font-weight: 400;
}

.video-info > .drone-video-info > .video-value > .value-box > h4 > span {
  font-size: 1.1rem;
}

.video-info > .drone-video-info > .video-value > .value-box > h2 {
  font-size: 0.9rem;
  font-weight: 300;
  margin-bottom: 0px
}

.video-info > .drone-video-info > .video-value > .value-box > h2 > span {
  font-size: 1.1rem;
}

.video-info > .drone-video-info > .video-coordinate {
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 2px;
}

.video-info > .drone-video-info > .video-coordinate > .coordinate-row {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  width: 35%;
}

.video-info > .drone-video-info > .video-coordinate > .coordinate-row > h4 {
  font-size: 0.9rem;
  color: #c9c9c9;
  margin-right: 5px;
  font-weight: 400;
}

.video-info > .drone-video-info > .video-coordinate > .coordinate-row > h2 {
  margin: 0;
  font-weight: 300;
  font-size: 0.9rem;
}

.video-info > .drone-video-info > .video-coordinate > .coordinate-row > h2 > span {
  font-size: 1.1rem;
}

/* mission point list */
.rm-point-list {
  position: absolute;
  right: 10px;
  top: 315px;
  width: 375px;
  height: 340px;
  background: #232323;
  border-radius: 5px;
  padding: 15px;
}

.rm-point-list > h2 {
  font-size: 1.4rem;
  color: #fff;
  font-weight: bold;
}

.rm-point-list > ul {
  height: 280px;
  max-height: 280px;
  overflow-y: auto;
}

.rm-point-list > ul > li {
  background: #3c3c3c;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.rm-list-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #707070;
  padding-bottom: 10px;
}

.rm-list-top > h4 {
  font-size: 1.4rem;
  font-weight: 300;
  color: #fff;
}

.rm-list-top > .loc-info > h5 {
  font-size: 1.2rem;
  color: #c9c9c9;
  font-weight: 400;
  margin-bottom: 5px;
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rm-list-top > .loc-info > h5 > span {
  font-size: 1.2rem;
  color: #fff;
  margin-left: 10px;
  font-weight: 300;
}

.rm-list-bottom {
  padding-top: 10px;
  display: flex;
  align-items: center;
}

.rm-list-bottom > .rm-value {
  display: flex;
  margin-right: 15px;
  align-items: center;
}

.rm-list-bottom > .rm-value > h5 {
  color: #c9c9c9;
  font-size: 1.2rem;
  margin-right: 10px;
}

.rm-list-bottom > .rm-value > h4 {
  font-size: 1.2rem;
  font-weight: 300;
  color: #fff;
}

.rm-list-bottom > .rm-value > h4 > span {
  margin-right: 3px;
}


/* station */
.station-view {
  position: absolute;
  top: 660px;
  right: 10px;
  width: 375px;
  height: 301px;
  background: #232323;
}

/*스테이션 라이브 사이즈 200 -> 211  변경*/
.station-live {
  width: 100%;
  height: 211px;
  background: #000000;
  position: relative;
}

.station-live > .play-on {
  width: 100%;
  height: 100%;
}

.station-live > .play-off {
  width: 100%;
  text-align: center;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.station-live > .play-off > img {
  width: 25px;
}

.station-live > .play-off > h2 {
  font-size: 1.2rem;
  color: #fff;
  margin-top: 10px;
}

.sv-con {
  width: 100%;
  height: 90px;
  color: #fff;
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #4f4f4f;
}

.sv-con > .sv-img {
  width: 80px;
  border-right: 1px solid #4f4f4f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sv-con > .sv-img > img {
  width: 50px;
}

.sv-con > .sv-info {
  width: calc(100% - 80px);
}

.sv-con > .sv-info > h4 {
  font-size: 1.4rem;
  font-weight: 300;
  padding: 5px 0 5px 10px;
  margin-bottom: 8px;
}

.sv-con > .sv-info > .sv-value {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.sv-con > .sv-info > .sv-value > .sv-situation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
}

.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 48%;
}

.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row > h4 {
  font-size: 1rem;
  font-weight: 300;
  color: #c9c9c9;
}

.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row > h5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: 300;
}

.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row > h5 > .sv-mark {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #ddd;
  border-radius: 10px;
  margin-right: 5px;
}

.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row > h5 > .sv-mark.sv-good {
  background: #1cdfa0;
}

.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row > h5 > .sv-mark.sv-bad {
  background: #ff3d3d;
}

.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row > h5 > span {
  font-size: 1rem;
  font-weight: 300;
}

/* modal css */
.sv-modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sv-modal-wrapper {
  width: 1200px;
}

.sv-modal-container {
  background-color: #232323;
  padding: 20px;
  border-radius: 5px;
}

.sv-modal-container > .modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #707070;
  padding-bottom: 20px;
}

.sv-modal-container > .modal-title > h3 {
  font-size: 1.6rem;
  margin-bottom: 10px;
  color: #fff;
}

.sv-modal-container > .modal-title > button {
  border: 0;
}

.sv-modal-content {
  margin: 15px 0;
}

.sv-modal-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #707070;
  padding-top: 20px;
}

.sv-modal-btn > .submit-btn {
  font-size: 1.4rem;
  background: #0080ff;
  color: #fff;
  border-radius: 5px;
  border: 0;
  width: 120px;
  margin: 0 5px;
}

.sv-modal-btn > .close-btn {
  font-size: 1.4rem;
  background: #3c3c3c;
  color: #fff;
  border-radius: 5px;
  border: 0;
  width: 120px;
  margin: 0 5px;
}

.sv-list-info {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sv-list-info > .info-count {
  display: flex;
  align-items: center;
}

.sv-list-info > .info-count > h4 {
  margin-right: 40px;
  font-weight: 300;
}

.sv-list-info > .info-count > h4 > span {
  font-size: 1.4rem;
  color: #c9c9c9;
}

.sv-list-info > .info-count > h4 > span:nth-child(2) {
  margin-left: 20px;
  color: #fff;
}

.sv-list-info > button {
  border: 0;
  background: #3c3c3c;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 5px;
}

.sv-list-info > button > img {
  width: 15px;
}

.sv-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  height: 400px;
  max-height: 400px;
  overflow-y: auto;

}

.sv-list > li {
  width: 24%;
  margin-bottom: 10px;
}

.sv-list > li > .st-view {
  position: relative;
  height: 110px;
  background: #101010;
}

.sv-list > li > .st-view > input {
  position: absolute;
  top: 5px;
  left: 5px;
}

.sv-list > li > .st-info {
  padding: 10px;
  background: #3c3c3c;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}

.sv-list > li > .st-info > .st-title > h2 {
  font-size: 1.4rem;
  color: #fff;
  margin-bottom: 7px;
}

.sv-list > li > .st-info > .st-title > h4 {
  color: #c9c9c9;
  font-size: 1.2rem;
  font-weight: 300;
}

.sv-list > li > .st-info > .st-situation > h4 {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.sv-list > li > .st-info > .st-situation > h4 > span {
  font-size: 1.2rem;
  color: #fff;
  font-weight: 300;
}

.sv-list > li > .st-info > .st-situation > h4 > .st-mark {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #ddd;
  border-radius: 10px;
  margin-right: 10px;
}

.sv-list > li > .st-info > .st-situation > h4 > .st-mark.st-good {
  background: #1cdfa0;
}

.sv-list > li > .st-info > .st-situation > h4 > .st-mark.st-bad {
  background: #eee;
}

.sv-list > li > .st-info > .st-situation > h4 > .st-mark.st-rtl {
  background: #FF4747;
}

.station-battery > h4 > .station-off{width: 100%;height: 15px;background: #575757;}
.station-battery > h4 > .station-on{width: 10px;height: 10px;background: #32e77a;border-radius: 10px;margin-right: 5px;}

.station-buttons > .map-btn-list {
  right: 390px;
}

.station-battery{display: flex;    padding: 10px;background: #3c3c3c;}
.station-battery > .station-battery-content{display: flex;}
.station-battery > .station-battery-content > .station-battery-box{position: relative;height: 18px;width: 35px;background: #eee;border-radius: 3px;margin-right: 5px;}
.station-battery > .station-battery-content > .station-battery-box > .battery-volume{position: absolute;top: 0;left: 0;height: 18px;width: 0%;background: #4f555e;border-radius: 3px;}

.station-battery > .station-battery-content > h4{font-size: 1.3rem;font-weight: 500;}
.station-battery > .station-battery-content > h4 > span{font-size: 1.2rem;}

.station-battery > h4{margin-left: 15px; display: flex;align-items: center;}
.station-battery > h4 > img{width: 10px;margin-right: 5px;}
.station-battery > h4 > span{font-size: 1.3rem;font-weight: 500;}


</style>
<script>
import {format} from "@/mixins/format";

import moment from "moment";
import Map from "@/components/map/Map";

import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";
import MapPolygonData from "@/components/map/data/MapPolygonData";
import MapMappingData from "@/components/map/data/MapMappingData";

import {fetchEvent} from "@/api/event";
import {fetchPointList, getPointTypeName, fetchPointCreate} from "@/api/point";

import {mapState} from "vuex";
import {stationPopup} from "@/util/popup";
import {ko} from "vuejs-datepicker/dist/locale";

import windDirection from "@/util/windDirection";
import {fetchMissionInfo, getMissionCameraList} from "@/api/mission";
import {fetchMissionLocationList} from "@/api/missionLocation";
// import videoPlayer from "@/components/video/videoPlayer";
import MapUtils from "@/components/common/MapUtils";
import {fetchChannel, fetchChannelList} from "@/api/channel";
import {getHlsUrl} from "@/components/common/LiveUtils";

//station API
import {fetchStationDeviceInfo, fetchStationDeviceDockList} from "@/api/station/stationDevice";
import {fetchStationMissionJob, fetchStationMissionJobStatus} from "@/api/station/stationMissionJob";
import {fetchStationDeviceChannelManagerInfo} from "@/api/station/stationDeviceChannelManager";
// import { fetchStationMissionPause } from "@/api/station/stationMission";
import UseConnectWebSocket from "@/station/websocket/use-connect-websocket";
import {messageHandler} from "@/station/websocket/message-handler-websocket";
import {fetchStationScheduleDetail} from "@/api/station/stationSchedule";
import {EDockModeCode, EModeCode, RainfallEnum} from '@/station/type/device'
import WebRTC_WOWZA from "@/components/video/WebRTC_WOWZA";
import Video from "@/components/video/videoPlayer";
import MapButtonComponent from "@/components/map/MapButtonComponent.vue";
import mapButtonEventBus from "@/components/map/mapButtonEventBus";
import {fetchChannelCheckStream} from "../../api/channel";
import {stationLicenceCheck} from "@/util/Licence";


stationPopup;

export default {
  components: {
    Map,
    WebRTC_WOWZA,
    Video,
    MapButtonComponent
  },
  name: "Main",
  mixins: [format],
  props: {
    address: {
      type: String,
      default: "",
    },
    weatherList: {
      type: Array,
      default: null
    },
    updatedDate: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      EDockModeCode: EDockModeCode,
      EModeCode: EModeCode,
      RainfallEnum: RainfallEnum,

      //index: 0,
      moment: moment,
      loadMap: 'kakao',
      viewSrc: "",

      isdrModelShow: true,
      eventInfo: null,
      contentsId: null,
      showStationListModal: false,
      location: [],
      lat: null,
      lng: null,
      locationSize: null,
      onIndexes: [1, 2, 3, 4, 5, 6],
      ko: ko,
      startYmd: null,
      endYmd: null,
      selectedCheckbox: null,
      currentPage: 0,
      size: 10,
      keywordType: "all",
      keyword: "",
      total: 0,

      checkedStations: [],

      mapUtils: new MapUtils(),

      channelList : [],
      droneChannelInfo: null,
      dockChannelInfo: null,
      cameraTypeList: [],

      //스테이션 관련 변수
      stationDeviceId: null,
      stationScheduleInfo: null,
      stationInfo: null,
      stationDeviceList: [],
      stationMissionJobLog: null,
      dockChannelManagerInfo: null,
      droneChannelManagerInfo: null,
      missionInfo: null,
      missionLocationList: [],
      storeDockInfo: null,
      storeDroneInfo: null,
      useConnectWebSocket: null,
      stationMissionStatusInfo: null,
      droneDistance: '-',
      isMissionEnd: false,
    };
  },
  computed: {
    stationName: function () {
      return this.stationInfo == null ? '-' : (this.stationInfo.managerData == null || this.stationInfo.managerData.tag == null ? '-' : this.stationInfo.managerData.tag) + '번 ' + this.stationInfo.deviceName
    },
    ...mapState({
      currentLang: state => state.store.currentLang,
      userType: state => state.store.userType,
      accountId: state => state.store.accountId,
      dockInfo: state => state.station.deviceDockInfo,
      droneInfo: state => state.station.deviceDroneInfo,
      deviceMissionStatus: state => state.station.deviceMissionStatus,
    }),
    totalDistance: function () {
      if (this.missionLocationList.length == 0) {
        return "-"
      }

      let total = 0;
      const locationList = this.missionInfo.missionType == "WayPoint" ? this.missionLocationList : this.mappingLineList
      for (var i = 0; i < locationList.length - 1; i++) {
        total += this.mapUtils.calculateDistance(locationList[i], locationList[i + 1])
      }
      total += this.mapUtils.calculateDistance(locationList[0], this.stationInfo.managerData)
      total += this.mapUtils.calculateDistance(locationList[locationList.length - 1], this.stationInfo.managerData)
      return total.toFixed(1)
    },
    missionDistance: function () {
      if (this.missionLocationList.length == 0) {
        return "-"
      }

      let total = 0;
      const locationList = this.missionInfo.missionType == "WayPoint" ? this.missionLocationList : this.mappingLineList
      for (var i = 0; i < locationList.length - 1; i++) {
        total += this.mapUtils.calculateDistance(locationList[i], locationList[i + 1])
      }
      return total.toFixed(1)
    },
    addLocalTime: function () {
      if (this.totalDistance == "-" || this.missionInfo == null || this.stationMissionJobLog == null || this.stationScheduleInfo == null) {
        return "-"
      }
      let sec = 0
      if (this.missionInfo.missionType == "WayPoint") {
        for (var i = 0; i < this.missionLocationList.length - 1; i++) {
          let distance = this.mapUtils.calculateDistance(this.missionLocationList[i], this.missionLocationList[i + 1])
          sec += distance / this.missionLocationList[i].autoFlightSpeed
        }
        sec += this.mapUtils.calculateDistance(this.missionLocationList[0], this.stationInfo.managerData) / this.missionInfo.autoFlightSpeed
        sec += this.mapUtils.calculateDistance(this.missionLocationList[this.missionLocationList.length - 1], this.stationInfo.managerData) / this.missionInfo.autoFlightSpeed
      } else {
        sec = this.totalDistance / this.missionInfo.autoFlightSpeed
      }

      let date = moment(moment(this.stationMissionJobLog.registerDate).format('YYYY.MM.DD') + "-" + this.stationScheduleInfo.localTime)
      date.add(sec, 'seconds')
      return date.format('HH:mm:ss')
    },
    stationDeviceListMap() {
      // stationDeviceList를 인덱스를 키로 하는 객체로 변환
      return this.stationDeviceList.reduce((map, item, index) => {
        map[index] = item;
        return map;
      }, {});
    },
    channelListMap() {
      // stationDeviceList를 인덱스를 키로 하는 객체로 변환
      return this.channelList.reduce((map, item) => {
        map[item.assignedDeviceId] = item;
        return map;
      }, {});
    },
    batteryStyle() {
      // 스타일 바인딩으로 width와 background-color를 동적으로 설정
      return this.stationDeviceList.reduce((map, item, index) => {
        var batteryPercentage = 0
        if(item.children.batteryPercent !== null || item.children.batteryPercent !== undefined) {
          batteryPercentage = item.children.batteryPercent
        }
        map[index] = {
          width: batteryPercentage + '%',
          backgroundColor: batteryPercentage <= 20 ? '#ff4d4f' : '#a4d868', // 20% 이하일 경우 빨간색
          transition: 'width 0.3s ease, background-color 0.3s ease',
        }
        return map;
      }, {} )
    },
  },
  watch: {
    currentLang(value) {
      this.mapInitData(value);
    },
    dockInfo: {
      handler(info) {

        if (this.stationInfo == null) {
          return
        }
        let findDockInfo = info[this.stationInfo.deviceSerial];

        if (findDockInfo === undefined) {
          this.dockChannelManagerInfo = null;
          this.dockChannelInfo = null;
        }

        if (findDockInfo.base !== undefined && findDockInfo.base.modeCode !== this.stationInfo.modeCode) {
          if (findDockInfo.base.modeCode === EDockModeCode.Working.code) {
            if (this.stationMissionJobLog == null) {
              this.getStationMissionJob()
            }
          } else {
            this.initStationMissionJob()
          }
          this.stationInfo.modeCode = findDockInfo.base.modeCode
        }

        if (this.dockChannelManagerInfo == null && this.stationInfo.modeCode !== undefined && this.stationInfo.modeCode !== EDockModeCode.Disconnected.code) {
          this.getStationChannelManagerInfo(this.stationInfo.stationDeviceId, "dock");
        }

        if (this.dockChannelManagerInfo != null && this.dockChannelInfo == null && this.stationInfo.modeCode !== undefined && this.stationInfo.modeCode !== EDockModeCode.Disconnected.code) {
          if (this.dockChannelManagerInfo.channelId != null) {
            this.getChannelInfo(this.dockChannelManagerInfo.channelId, "dock")
          }
        }

        this.storeDockInfo = findDockInfo;

        if (findDockInfo.base != undefined && this.storeDockInfo.base.latitude != 0 && this.storeDockInfo.base.longitude != 0) {
          this.stationInfo.managerData.lat = this.storeDockInfo.base.latitude;
          this.stationInfo.managerData.lng = this.storeDockInfo.base.longitude;
          this.deviceInfoLatLng("Dock", this.stationInfo)
        }

        if (this.stationDeviceList.length == 0) {
          return;
        }

        this.stationDeviceList.forEach((item, idx) => {
          let findDockInfo = info[item.device.deviceSerial];
          if (findDockInfo != undefined && findDockInfo.base != undefined) {

            if (findDockInfo.base.modeCode != item.device.modeCode) {
              item.device.modeCode = findDockInfo.base == undefined ? null : findDockInfo.base.modeCode;
              this.$set(this.stationDeviceList, idx, item);
            }

            if(findDockInfo.base.droneChargeState !== undefined && findDockInfo.base.droneChargeState.capacityPercent !== item.children.batteryPercent) {
              item.children.batteryPercent = findDockInfo.base.droneChargeState.capacityPercent
              this.$set(this.stationDeviceList, idx, item);
            }
          }
        });
      },
      deep: true
    },
    droneInfo: {
      handler(info) {
        if (this.stationInfo == null) {
          return
        }
        let findDroneInfo = info[this.stationInfo.childSerial];
        if (findDroneInfo == undefined) {
          if (this.storeDroneInfo != null) {
            this.$refs.map.onDeleteMarkerAt(MapDataType.ID_DRONE, this.storeDroneInfo.sn);
          }

          this.initStationMissionJob()
          this.storeDroneInfo = null;
          this.droneChannelManagerInfo = null;
          this.droneChannelInfo = null;
          return
        }


        this.storeDroneInfo = findDroneInfo;
        if (this.stationInfo.childStationDevice != null) {

          if (this.storeDroneInfo.modeCode !== this.stationInfo.childStationDevice.modeCode) {
            this.stationInfo.childStationDevice.modeCode = this.storeDroneInfo.modeCode
          }

          if (this.droneChannelManagerInfo == null && this.stationInfo.childStationDevice.modeCode !== undefined && this.stationInfo.childStationDevice.modeCode !== EModeCode.Disconnected.code) {
            this.getStationChannelManagerInfo(this.stationInfo.childStationDevice.stationDeviceId, "drone");
          }

          if (this.droneChannelManagerInfo != null && this.droneChannelInfo == null && this.stationInfo.childStationDevice.modeCode !== undefined && this.stationInfo.childStationDevice.modeCode !== EModeCode.Disconnected.code) {
            if (this.droneChannelManagerInfo.channelId != null) {
              this.getChannelInfo(this.droneChannelManagerInfo.channelId, "drone")
            }
          }

          if (this.storeDroneInfo.payload != null && this.storeDroneInfo.payload.length > 0) {
            this.stationInfo.childStationDevice.managerData.gimbalDirection = this.storeDroneInfo.attitudeHead - this.storeDroneInfo.payload[0].gimbalYaw.toFixed(2);
            this.stationInfo.childStationDevice.managerData.gimbalPitch = this.storeDroneInfo.payload[0].gimbalPitch;
            this.stationInfo.childStationDevice.managerData.gimbalRoll = this.storeDroneInfo.payload[0].gimbalRoll;
          }
          this.stationInfo.childStationDevice.managerData.rotate = this.storeDroneInfo.attitudeHead;
          this.stationInfo.childStationDevice.managerData.lat = findDroneInfo.latitude
          this.stationInfo.childStationDevice.managerData.lng = findDroneInfo.longitude

          this.deviceInfoLatLng("Drone", this.stationInfo.childStationDevice)
          this.changeDroneDistance()
        }

        if (this.storeDockInfo != null && this.storeDockInfo.modeCode === EDockModeCode.Working.code && this.stationMissionJobLog === null) {
          this.getStationMissionJobStatusInfo(this.stationDeviceId)
        }

        if (this.stationDeviceList.length == 0) {
          return;
        }

        this.stationDeviceList.forEach((item, idx) => {
          let findDroneInfo = info[item.children.deviceSerial];
          if (findDroneInfo != undefined) {
            if (findDroneInfo.modeCode != item.children.modeCode) {
              item.children.modeCode = findDroneInfo.modeCode;
              this.$set(this.stationDeviceList, idx, item);
            }
          }
        });

      },
      deep: true
    },
    deviceMissionStatus: {
      handler(info) {
        if (this.stationInfo == null) {
          return
        }
        let findMissionStatus = info[this.stationInfo.deviceSerial];
        if (findMissionStatus === undefined) {
          this.dockChannelManagerInfo = null;
          this.dockChannelInfo = null;
          return
        }

        this.stationMissionStatusInfo = findMissionStatus;
      },
      deep: true
    },
  },
  beforeRouteEnter(to, from, next) {
    if (stationLicenceCheck(localStorage.getItem("accountId"))) {
      next()
    } else {
      next("/demo/404")
    }
  },
  mounted() {
    this.$refs.map.isShowLine = true;
    this.mapInitData(this.$store.state.store.currentLang);
    this.stationDeviceId = this.$route.params.stationDeviceId;

    this.cameraInit();

    this.getStationInfo();
    this.getStationMissionJob();
    this.initButtonMap()
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", {nav: 9, sub: 2});
    this.useConnectWebSocket = new UseConnectWebSocket();
  },
  methods: {
    getHlsUrl,
    async getChannelStationList() {
      var params = {
        eventId: this.eventId,
        assignedDeviceTypes : "Station",
        assignedStatus: "Assigned",
      };
      await fetchChannelList(params).then((res) => {
        if (res.data.result === 0) {
          let newChannelList = res.data.data.content;
          newChannelList.forEach((item) => {
            let findIndex = this.channelList.findIndex((find) => find.channelId === item.channelId)
            if(findIndex === -1) {
              item.isLoad = false;
              this.channelList.push(item)
            }
          });
        }
      });
    },
    playerRemoveList(id){
      let findIndex = this.channelList.findIndex(item => item.channelId == id);
      if(findIndex != -1){
        this.channelList.splice(findIndex,1);
      }
    },
    getLiveData(referenceDeviceId) {
      let findIndex = this.channelList.findIndex((find) => find.assignedDeviceId === referenceDeviceId)
      if(findIndex == -1) {
        return null
      }
      return this.channelList[findIndex]
    },
    playerLoadDataList(id, isLoad) {

      let findIndex = this.channelList.findIndex((channel)=> {
        return channel.channelId === id
      });
      if (findIndex != -1) {
        this.channelList[findIndex].isLoad = isLoad;
        this.$set(this.channelList, findIndex, this.channelList[findIndex]);
      }
    },
    moveMapCenter(type) {
      if (type === "Dock" && this.storeDockInfo != null && this.storeDockInfo.base != undefined) {
        this.$refs.map.onLngLatToMoveCenter(this.storeDockInfo.base.longitude, this.storeDockInfo.base.latitude);
      }
      if (type === "Drone" && this.storeDroneInfo != null) {
        this.$refs.map.onLngLatToMoveCenter(this.storeDroneInfo.longitude, this.storeDroneInfo.latitude);
      }
    },
    playerReload(stationDeviceId, type) {
      if (stationDeviceId !== null && type !== undefined) {
        if (type === "Dock") {
          this.dockChannelInfo = null;
          this.dockChannelManagerInfo = null;
        }
        if (type === "drone") {
          this.$refs.dronePlayer.playStop();
          this.droneChannelInfo = null;
          this.droneChannelManagerInfo = null;
        }
        this.getStationChannelManagerInfo(stationDeviceId, type);
      }
    },
    changeDroneDistance() {
      const locationList = this.missionInfo.missionType == "WayPoint" ? this.missionLocationList : this.mappingLineList
      if (this.missionInfo == null || locationList == null || locationList.length === 0) {
        this.droneDistance = "-"
        return
      }

      if (this.storeDroneInfo == null) {
        this.droneDistance = "-"
        return
      }

      if (this.stationMissionStatusInfo == null || this.stationMissionStatusInfo.ext == null) {
        this.droneDistance = "-"
        return
      }

      let droneBreakPoint = this.stationMissionStatusInfo.ext.breakPoint;
      let droneWaypointIndex = this.stationMissionStatusInfo.ext.currentWaypointIndex;

      if (droneWaypointIndex === 0) {
        this.droneDistance = "-"
        return
      }

      let droneDistance = 0;
      if (locationList.length === droneWaypointIndex) {
        this.droneDistance = this.missionDistance
        return
      }

      let filter = locationList.filter((item, index) => {
        return index <= (droneWaypointIndex - 1)
      })
      //let filter = locationList.filter(item => item.index <= (droneWaypointIndex - 1))
      if (filter.length > 0) {
        for (var i = 0; i < filter.length - 1; i++) {
          droneDistance += this.mapUtils.calculateDistance(filter[i], filter[i + 1])
        }
      }
      let currentLocation = locationList[droneWaypointIndex - 1]

      let loc = {
        lat: droneBreakPoint != null ? droneBreakPoint.latitude : this.storeDroneInfo.latitude,
        lng: droneBreakPoint != null ? droneBreakPoint.longitude : this.storeDroneInfo.longitude
      }
      let currentLocationDistance = this.mapUtils.calculateDistance(currentLocation, loc)
      droneDistance = droneDistance + currentLocationDistance;
      this.droneDistance = droneDistance.toFixed(1)
    },
    initButtonMap() {
      this.$refs.buttonMap.showMapTypeChange()
      this.$refs.buttonMap.showFollow()
      mapButtonEventBus.$emit('positionAndline', true)
    },
    uninitButtonMap() {
      this.$refs.buttonMap.noShowMapTypeChange()
      this.$refs.buttonMap.noShowFollow()
    },
    //region 스테이션 API -----------------------------------------------------------------
    getStationListInfo(idx) {
      if (this.stationDeviceList.length < idx - 1) {
        return null
      }
      return this.stationDeviceList[idx]
    },
    getStationDeviceDockList() {
      if (this.eventId == null) {
        alert("창 다시 불러주세요")
        return
      }

      let params = {
        eventId: this.eventId
      }
      fetchStationDeviceDockList(params).then((res) => {
        if (res.status == 200 && res.data.result == 0) {
          this.stationDeviceList = res.data.data.content.sort((a, b) => a.device.managerData.tag - b.device.managerData.tag);
          this.stationDeviceList.forEach((item) => {
            let findDroneInfo = this.droneInfo[item.children.deviceSerial];
            item.children.modeCode = findDroneInfo == undefined ? null : findDroneInfo.modeCode;
            let findDockInfo = this.dockInfo[item.device.deviceSerial];
            item.device.modeCode = findDockInfo == undefined || findDockInfo.base == undefined ? null : findDockInfo.base.modeCode;
          })
        }
      }).catch((err) => {
        console.error("stationList")
        console.error(err)
      });
    },
    stationWebsocketSetting() {
      this.useConnectWebSocket.onConnectWebSocket(messageHandler, this.eventId);
      this.useConnectWebSocket.onWebSocketMounted();
    },
    getStationChannelManagerInfo(stationDeviceId, type) {
      fetchStationDeviceChannelManagerInfo(stationDeviceId).then((res) => {
        if (res.status === 200 && res.data.result === 0) {
          if (type === "dock") {
            this.dockChannelManagerInfo = res.data.data
            if (this.dockChannelManagerInfo.channelId != null) {
              this.getChannelInfo(this.dockChannelManagerInfo.channelId, "dock")
            }
          }
          if (type === "drone") {
            this.droneChannelManagerInfo = res.data.data
            if (this.droneChannelManagerInfo.channelId != null) {
              this.getChannelInfo(this.droneChannelManagerInfo.channelId, "drone")
            }
          }
        }
      });
    },
    getStationInfo() {
      fetchStationDeviceInfo(this.stationDeviceId).then((res) => {
        if (res.status == 200 && res.data.result == 0) {
          this.stationInfo = res.data.data;
          this.eventId = this.stationInfo.managerData.eventId;
          //화면 추가 정보 불러오기 함수 호출
          //소켓 연결
          this.stationWebsocketSetting();
          //현장 정보
          this.getEvent();
          this.getEventPoint();
          this.deviceInfoLatLng("Dock", this.stationInfo)
        }
      });
    },
    getStationSchedule(stationScheduleId) {
      fetchStationScheduleDetail(stationScheduleId).then((res) => {
        if (res.status == 200 && res.data.result == 0) {
          this.stationScheduleInfo = res.data.data;

        }
      });
    },
    removeMapMission() {
      this.$refs.map.onDeleteMarkerList(MapDataType.ID_MAPPING_MARKER);
      this.$refs.map.onDeleteMappingLineList(MapDataType.ID_MAPPING_POLYLINE)
      this.$refs.map.onDeletePolyLineList(MapDataType.ID_MAPPING_POLYGON_LINE)
      this.$refs.map.onDeletePolygonList(MapDataType.ID_MAPPING_HIDDEN_POLYGON)
      this.$refs.map.onDeleteLineList(MapDataType.ID_MAPPING_LINE)
    },
    removeMapWaypoint() {
      this.$refs.map.onDeleteMarkerList(MapDataType.ID_WAYPOINT);
      this.$refs.map.onDeleteLineList(MapDataType.ID_WAYPOINT)
    },
    initStationMissionJob() {
      if (this.missionInfo != null && this.missionInfo.missionType === "Mapping") {
        this.removeMapMission()
      }
      if (this.missionInfo != null && this.missionInfo.missionType === "WayPoint") {
        this.removeMapWaypoint()
      }

      this.missionLocationList = [];
      this.stationScheduleInfo = null;
      this.stationMissionJobLog = null;
      this.missionInfo = null;
    },
    getStationMissionJob() {
      let stationDeviceId = this.stationDeviceId;
      fetchStationMissionJob(stationDeviceId).then((res) => {
        if (res.status == 200 && res.data.result == 0) {
          if (res.data.data.stationMissionJobLogId != null) {
            this.stationMissionJobLog = res.data.data.stationMissionJobLog
            let missionId = this.stationMissionJobLog.missionId
            let stationScheduleId = res.data.data.stationScheduleItemLog.scheduleId
            this.getMissionInfo(missionId)
            this.getStationSchedule(stationScheduleId)
            this.getStationMissionJobStatusInfo()
          }
        }
      });
    },
    //endregion -----------------------------------------------------------------
    deviceInfoLatLng(type, info) {
      let mapDataType = null;
      let name = info.deviceName;
      let location = {
        lat: null,
        lng: null,
        azimuth: null,
        gimbalDirection: null,
      }

      location.lat = info.managerData.lat;
      location.lng = info.managerData.lng;
      if (type === "Dock") {
        mapDataType = MapDataType.ID_SCHEDULE_STATION
      }

      if (type === "Drone") {
        mapDataType = MapDataType.ID_DRONE;
        location.azimuth = info.managerData.rotate;
        location.gimbalDirection = info.managerData.gimbalDirection;
      }
      if (mapDataType != null && name != null) {
        this.createMapMarker(mapDataType, info.sn, name, location)
        return;
      }
    },

    getChannelInfo(channelId, type) {
      fetchChannelCheckStream(channelId).then((res) => {
        let seveType = type
        if (res.data.data.bytesIn == 0 || res.data.data.bytesIn === null) {
          return
        }
        fetchChannel(channelId).then((res) => {
          if (res.data.result === 0) {

            if (seveType === "drone") {
              this.droneChannelInfo = res.data.data
            }
            if (seveType === "dock") {
              this.dockChannelInfo = res.data.data;
            }
          } else {
            let message = res.data.message;
            alert(message);
          }
        }).catch((err) => {
          console.error("getChannelInfo", err);
        });

      })
    },

    //region 지도 관련 함수 -----------------------------------------------------------------
    mapInitData(value) {
      if (value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      this.getEventPoint();
      if (this.contentsId != null) {
        this.getLocation(this.contentsId);
      }
    },
    createMissionMapLine(id, locationGroup) {
      let mapDataType = MapDataType.ID_WAYPOINT
      var mapLineData = new MapLineData();
      mapLineData.id = id;
      mapLineData.locationGroup = locationGroup;
      mapLineData.isMain = true;
      mapLineData.isShow = true;
      if (this.$refs.map.onIsLine(mapDataType, id)) {
        this.$refs.map.onModifyLineReplaceAt(mapDataType, mapLineData);
      } else {
        this.$refs.map.onCreateLine(mapDataType, mapLineData);
      }
    },
    setMapCenterForMissionLocation(location) {
      this.$refs.map.onLngLatToMoveCenter(location.lng, location.lat);
    },
    createMapMarker(mapDataType, id, name, location) {
      let mapMarkerData = new MapMarkerData();
      mapMarkerData.id = id;
      mapMarkerData.lng = location.lng;
      mapMarkerData.lat = location.lat;
      mapMarkerData.name = name;
      mapMarkerData.userName = name;
      mapMarkerData.userDepartmentName = "";

      if (mapDataType === MapDataType.ID_DRONE) {
        mapMarkerData.rotate = location.azimuth;
        mapMarkerData.gimbalDirection = location.gimbalDirection;
      }

      if (this.$refs.map.onIsMarker(mapDataType, id)) {
        this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
      } else {
        this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
      }
    },
    //endregion

    //region 미션 관련 함수 -----------------------------------------------------------------
    getStationMissionJobStatusInfo() {
      fetchStationMissionJobStatus(this.stationDeviceId).then((res) => {
        if (res.data.result == 0) {
          this.stationMissionStatusInfo = res.data.data;
        }
      })
    },
    getCameraListId(id) {
      return this.cameraTypeList.findIndex(item => {
        return item.id == id
      })
    },
    cameraInit() {
      getMissionCameraList().then(res => {
        var data = JSON.parse(res.data.data)

        this.cameraTypeList = data.DJI
      }).then(() => {
      })
    },

    getMissionInfo(missionId) {
      fetchMissionInfo(missionId).then((res) => {
        if (res.data.result == 0) {
          this.missionInfo = res.data.data;
          this.altitude = res.data.data.altitude
          this.title = res.data.data.title
          this.autoFlightSpeed = res.data.data.autoFlightSpeed
          this.finishedActionType = res.data.data.finishedActionType
          this.getMissionLocation(missionId)
        }
      })
    },
    getMissionLocation(missionId) {
      let params = {
        eventId: this.eventId,
        missionId: missionId
      };
      fetchMissionLocationList(params).then((res) => {
        if (res.status == 200 && res.data.result == 0) {
          this.missionLocationList = res.data.data.content

          let missionType = this.missionInfo.missionType

          if (missionType == "Mapping") {
            let mappingData = this.settingMappingData();
            this.missionLocationList.forEach((item) => {
              this.setCreatePolygonMarker(mappingData, item)
            });
          }
          if (missionType == "WayPoint") {
            let sort = this.missionLocationList.sort((a, b) => {
              return a.index - b.index
            })
            this.setMissionWaypointToMap(sort)
          }

        }
      });
    },

    setMissionWaypointToMap(list) {
      let mapDataType = MapDataType.ID_WAYPOINT
      if (list.length > 0) {
        this.$refs.map.onLngLatToMoveCenter(list[0].lng, list[0].lat)

        list.forEach((item) => {
          let index = item.index + 1
          let mapMarkerData = new MapMarkerData();
          mapMarkerData.id = index;
          mapMarkerData.lng = item.lng;
          mapMarkerData.lat = item.lat;
          mapMarkerData.rotate = item.azimuth;
          mapMarkerData.name = index;

          if (this.$refs.map.onIsMarker(mapDataType, index)) {
            this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
          } else {
            this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
          }
        })
        this.createMissionMapLine("way-point" + list.index, list);
      }
    },
    /**폴리곤 생성.*/
    setCreatePolygon(mapDataType) {
      var mapPolygonDate = new MapPolygonData();
      if (mapDataType == MapDataType.ID_MAPPING_HIDDEN_POLYGON) {
        var centerInfo = this.setCreateCenterInfo()
        mapPolygonDate.setPolygon(MapDataType.ID_MAPPING_HIDDEN_POLYGON)
        mapPolygonDate.setLatLng(centerInfo)
        mapPolygonDate.posList = this.setCreateSqurePolygon(centerInfo, centerInfo.distance, Number(this.missionInfo.info.angle))
        mapPolygonDate.line = mapPolygonDate.posList
      }
      this.$refs.map.onCreateMappingPolygon(mapPolygonDate, mapDataType);
    },
    setCreatePolygonMarker(mappingData, location) {
      let mapDataType = MapDataType.ID_MAPPING_MARKER
      let mapMarkerData = new MapMarkerData();
      let index = location.index + 1
      mapMarkerData.id = index;
      mapMarkerData.lng = location.lng;
      mapMarkerData.lat = location.lat;
      mapMarkerData.rotate = 0;
      mapMarkerData.name = index;

      if (this.$refs.map.onIsMarker(mapDataType, index)) {
        this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
      } else {
        this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
      }
      if (index > 1) {
        let slice = this.missionLocationList.slice(index - 2, index);
        this.setCreateMapLine(index - 1, slice);
      }
      this.setCreateLastLine(mappingData, index)
    },
    /**매핑 영역안에 라인 만들때 사용 */
    setCreateMapLine(id, locationGroup) {
      let mapDataType = MapDataType.ID_MAPPING_POLYGON_LINE
      var mapLineData = new MapLineData();
      mapLineData.id = id;
      mapLineData.locationGroup = locationGroup;
      mapLineData.isMain = true;
      mapLineData.isShow = true;
      if (this.$refs.map.onIsLine(mapDataType, id)) {
        this.$refs.map.onModifyLineReplaceAt(mapDataType, mapLineData);
      } else {
        this.$refs.map.onCreateLine(mapDataType, mapLineData);
      }
    },
    /**마커가 3개이상부터 찍히면 첫번째 포인트와 마지막 포인트 연결함 */
    setCreateLastLine(mappingData, index) {
      if (index > 2) {
        let slice = [
          this.missionLocationList[0], // 첫 번째 요소
          this.missionLocationList[index - 1] // 마지막 요소
        ];
        this.setCreateMapLine(index, slice);
        this.setCreateMappingLine(mappingData)
      }
    },
    setCreateMappingLine(mappingData) {
      /**
       *  1. 각도 , 간격, 비행높이 정보를 보낸다.
       *  2. 매핑 뒤에 둘 히든 폴리곤을 생성한다.
       *  3. 히든폴리곤과 다각형에 라인을 생성한다.
       */
      this.sendMappingData(mappingData);

      /**mappingType은 정사영상이냐, 방제용이냐*/
      this.setCreatePolygon(MapDataType.ID_MAPPING_HIDDEN_POLYGON);
      this.$refs.map.onCreateMappingLine("Orthophoto", this.polygonType)
    },
    /**매핑 영역 밑에 깔리는 폴릭곤 */
    setCreateSqurePolygon(point, distance, angle) {
      var polygonPosList = []

      var rightPoint = this.mapUtils.calculateNewPosition(point, 90 + angle, distance);
      polygonPosList.push(this.mapUtils.calculateNewPosition(rightPoint, 0 + angle, distance, polygonPosList.length));
      polygonPosList.push(this.mapUtils.calculateNewPosition(rightPoint, 180 + angle, distance, polygonPosList.length));


      var leftPoint = this.mapUtils.calculateNewPosition(point, 270 + angle, distance);
      polygonPosList.push(this.mapUtils.calculateNewPosition(leftPoint, 180 + angle, distance, polygonPosList.length));
      polygonPosList.push(this.mapUtils.calculateNewPosition(leftPoint, 0 + angle, distance, polygonPosList.length));

      return polygonPosList
    },
    sendMappingData(mappingData) {
      this.$refs.map.sendMappingData(mappingData)
    },
    /**다각형 도형의 가운데 정보를 생성한다.*/
    setCreateCenterInfo() {
      var maxDistance = 0
      var center = this.mapUtils.calculateCentroid(this.missionLocationList) //다각형 중점 구하기

      this.missionLocationList.forEach(item => { //다각형 중점에서부터의 각포인트 최대 거리 구하기.
        var distance = this.mapUtils.calculateDistance(item, center)
        if (maxDistance < distance) maxDistance = distance
      })
      var data = {lat: center.lat, lng: center.lng, distance: maxDistance}
      return data
    },
    settingMappingData() {
      let mappingData = null;
      if (this.missionInfo != null) {
        var findIndex = this.getCameraListId(this.missionInfo.info.cameraType);
        var camera = JSON.parse(JSON.stringify(this.cameraTypeList[findIndex]));
        mappingData = new MapMappingData()
        this.missionInfo.info.altitude = this.missionInfo.altitude;
        mappingData.setApiData(this.missionInfo.info)
        mappingData.cameraType = camera;
        this.sendMappingData(mappingData);
      }
      return mappingData
    },
    //endregion

    getEvent() {
      if (this.eventId == undefined) {
        return;
      }

      fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;

          if (this.$refs.map == undefined) {
            return;
          }
          if (this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
            if (this.stationInfo.managerData.lng != null && this.stationInfo.managerData.lat != null) {
              this.$refs.map.onLngLatToMoveCenter(this.stationInfo.managerData.lng, this.stationInfo.managerData.lat)
            } else {
              this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
            }
            this.$refs.map.onCreateMapMarker(this.eventId, this.eventInfo.lng, this.eventInfo.lat, MapDataType.ID_SITE);
            this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);
          } else {
            this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
          }
        }
      });
    },
    async getEventPoint() {
      if (this.eventId == undefined) {
        return;
      }
      let params = {
        eventId: this.eventId,
        serverTime: null,
      };
      await fetchPointList(params).then((res) => {
        if (res.data.result == 0) {
          this.pointServerTime = res.data.data.serverTime
          this.pointCount = res.data.data.total
          this.pointList = res.data.data.content
          this.pointList.forEach((element) => {
            element.selectId = element.index;
            element.selectType = "Point";
            element.isOwner = false;
            let pointMapMarker = new MapMarkerData();
            element.name = this.$t(getPointTypeName(element.type));
            pointMapMarker.setPointData(element);
            this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
          })
        }
      });
    },
    reset() {
      this.currentPage = 0;
      this.keyword = "";
      this.keywordType = "all";
      this.startYmd = null;
      this.endYmd = null;
    },

    //region 날씨
    humidityBinding(inputData) {
      if (inputData == undefined) {
        return "ic-minus";
      }

      let data = Number(inputData.split(" ")[0]);
      let humidity;
      if (0 <= data && data < 20) {
        humidity = 1;
      } else if (20 <= data && data < 40) {
        humidity = 2;
      } else if (40 <= data && data < 80) {
        humidity = 3;
      } else if (80 <= data && data < 90) {
        humidity = 4;
      } else if (90 <= data && data <= 100) {
        humidity = 5;
      }

      return "humidity_" + humidity;
    },
    imgBinding(data) {
      let imgName;
      if (data.thunderstroke == undefined || data.rainCode == undefined || data.skyCode == undefined) {
        return "ic-minus";
      }

      if (data.thunderstroke != "0") {
        imgName = "thunder";
      } else if (data.rainCode != "0") {
        imgName = "raincode_" + data.rainCode;
      } else if (data.skyCode != "0") {
        imgName = "skycode_" + data.skyCode;
      }
      return imgName;
    },
    convertWindDirection(data) {
      return windDirection[Math.floor((Number(data) + 11.25) / 22.5)];
    },
    //endregion 날씨

    //region video
    playerLoadData(id, isLoad) {
      if (this.dockChannelInfo != null && this.dockChannelInfo.channelId === id) {
        this.$set(this.dockChannelInfo, "isLoad", isLoad);
      }
      if (this.droneChannelInfo != null && this.droneChannelInfo.channelId === id) {
        this.$set(this.droneChannelInfo, "isLoad", isLoad);
      }
    },
    //endregion video

    onReload() {
      this.$parent.weatherList = [];
      this.$parent.getWeather();
    },
    toggleCheck(index) {
      const idx = this.checkedStations.indexOf(index);
      if (idx > -1) {
        // 이미 선택된 경우 선택 해제
        this.checkedStations.splice(idx, 1);
      } else {
        // 선택되지 않은 경우 선택
        this.checkedStations.push(index);
      }
    },
    onPointCreateModalShow(isShow) {
      if (this.selectData != null && isShow) {
        if (this.getSelectTypeToMapDataType(this.selectData.selectType) == MapDataType.ID_POINT) {
          var filter = this.pointList.filter((item) => item.selectId == this.selectData.selectId);
          if (filter.length > 0) {
            this.handleSelectEl(filter[0]);
          }
        }
      }
    },
    onMapItemClick(id, type, isMain) {
      var findList = null;
      switch (type) {
        case MapDataType.ID_POINT :
          findList = this.pointList;
          break;
        case MapDataType.ID_DRONE :
          findList = this.deviceList;
          break;
        case MapDataType.ID_STREAMER :
        case MapDataType.ID_USER :
        case MapDataType.ID_CONTROLLER :
          findList = this.workerList;
          break;
        default :
          findList = null;
      }

      if (findList != null) {
        isMain;
        let findIndex = findList.findIndex((item) => item.selectId == id);
        if (findIndex != -1) {
          let findData = findList[findIndex];
          this.handleSelectEl(findData);
        }
      }
    },
    onPointCreate(data) {
      var sendData = data;
      fetchPointCreate(this.eventId, sendData).then((res) => {
        if (res.data.result == 0) {
          this.$refs.map.onPointCreateModalClose();
        }
      });
    },
    isOn(index) {
      return this.onIndexes.includes(index);
    },
    controlMode() {
      const userConfirmed = confirm(this.$t('station-control-alert-mode'))
      if (userConfirmed) {
        // if((this.missionInfo != null && this.missionInfo.missionType !== "Mapping") && this.storeDroneInfo != null && this.storeDroneInfo.modeCode !== undefined && this.storeDroneInfo.modeCode !== EModeCode.Disconnected.code) {
        //   let param = {
        //     missionId: null,
        //     stationDeviceId : this.stationInfo.stationDeviceId,
        //     cls: "dji"
        //   }
        //   fetchStationMissionPause(param).then((res) => {
        //     if (res.status == 200 && res.data.result == 0) {
        //       console.log('미션 일시중지 성공')
        //     }
        //   }).catch((err) => {
        //     console.error('미션 일시중지 실패', err.response.data.result, err.response.data.message)
        //   });
        // }
        this.$router.push({name: "stationControlMode"})
      } else {
        console.log('사용자가 취소를 선택했습니다.')
      }
    },
    selectStation() {
      if (this.selectedCheckbox == null) {
        alert(this.$t('station-control-alert-select'))
        return;
      }
      const userSelected = confirm(this.$t('station-control-alert-select-move'))
      if (userSelected) {
        this.showStationListModal = false;
        this.stationDeviceId = this.getStationListInfo(this.selectedCheckbox).device.stationDeviceId
        window.location.href = `/${this.accountId}/stationControl/${this.stationDeviceId}`
      }
    },
    openStationListModal() {
      this.stationDeviceList = [];
      this.channelList = [];
      this.getChannelStationList()
      this.getStationDeviceDockList()
      if (this.eventId == null) {
        return;
      }
      this.selectedCheckbox = null;
      this.showStationListModal = true;

    },
    closeStationListModal() {
      this.stationDeviceList = [];
      this.channelList = [];
      this.selectedCheckbox = null;
      this.showStationListModal = false;
    },
    stationListModalRefresh() {
      this.stationDeviceList = [];
      this.channelList = [];
      this.selectedCheckbox = null;
      this.getChannelStationList()
      this.getStationDeviceDockList()
    },
    onCheckboxChange(index) {
      // 이미 선택된 체크박스를 다시 클릭하면 선택을 해제
      if (this.selectedCheckbox === index) {
        this.selectedCheckbox = null;
      } else {
        this.selectedCheckbox = index;
      }
    },
    switchViews() {
      // console.log(this.$refs.map); // map 참조 확인
      // console.log(this.$refs.video); // video 참조 확인
      const bigViewElement = this.$refs.map_div;
      const smViewElement = this.$refs.smView;

      const mapElement = this.$refs.map_div2;
      const videoElement = this.$refs.video;

      // bigViewElement.appendChild(videoElement)
      // smViewElement.appendChild(mapElement)
      if (bigViewElement.contains(videoElement)) {
        //init
        this.initButtonMap()
        smViewElement.appendChild(videoElement);
        bigViewElement.appendChild(mapElement);
        this.$refs.video.style.height = '100%';
      } else {
        // videoElement가 smViewElement에 있는 경우, 위치를 스위칭
        this.uninitButtonMap()

        bigViewElement.appendChild(videoElement);
        smViewElement.appendChild(mapElement);

        this.$refs.smView.style.flexDirection = 'column-reverse';
      }
      this.$refs.map.setLayout()
      if (this.stationInfo.childStationDevice != null && this.stationInfo.childStationDevice.managerData.lat != null && this.stationInfo.childStationDevice.managerData.lng !== null) {
        this.$refs.map.onLngLatToMoveCenter(this.stationInfo.childStationDevice.managerData.lng, this.stationInfo.childStationDevice.managerData.lat)
        return
      }
      if (this.stationInfo.managerData.lat != null && this.stationInfo.managerData.lng !== null) {
        this.$refs.map.onLngLatToMoveCenter(this.stationInfo.managerData.lng, this.stationInfo.managerData.lat)
        return
      }
    },
    setMappingLineList(mappingLineList) {
      this.mappingLineList = mappingLineList
    },
    playerRemoveId(playerId) {
      if (this.dockChannelInfo.channelId === playerId) {
        this.$set(this.dockChannelInfo, "isLoad", false);
        this.dockChannelInfo = null
      } else if (this.droneChannelInfo.channelId === playerId) {
        this.$set(this.droneChannelInfo, "isLoad", false);
        this.droneChannelInfo = null
      }
    },
  },
  beforeDestroy() {
    this.useConnectWebSocket.onWebSocketUnmounted();
  },
};
</script>

